import $axios from "@/plugins/axios"

const RealEstateService = {
  async getRealEstates(payload){
    try{
      const {data} =  await $axios.get(`/mobi/real-estates/${payload.hotel_id}/${payload.menu_id}`);
      return data;
    }catch(e){
      return e;
    }
  },
  async getRealEstate(id){
    try{
      const {data} = await $axios.get(`/mobi/real-estate/${id}`);
      return data;
    } catch(e){
      return e;
    }
  },

};
export default RealEstateService;
