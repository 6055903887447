import $axios from "@/plugins/axios";

const BottomBannerService = {
    async getBottomBanners(hotel_id){
        try{
            const {data} = await $axios(`bottom-banners/${hotel_id}`)
            return data;
        }catch(e){
            return e;
        }
    },
    async getBottomBanner(banner_id){
        try{
            const {data} = await $axios.get(`bottom-banner/${banner_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async postBottomBanner(banner){
        try{
            const {data} = await $axios.post(`bottom-banner`,banner);
            return data;
        }catch(e){
            return e;
        }
    },
    async putBottomBanner(banner){
        try{
            const {data} = await $axios.put(`bottom-banner/${banner.id}`,banner);
            return data
        }catch(e){
            return e;
        }
    },
    async deleteBottomBanner(banner_id){
        try{
            const {data} = await $axios.delete(`bottom-banner/${banner_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async exportBottomBannerStatistics(hotel_id){
        try{
            const {data} = await $axios.get(`export-bottom-banner-statistic/${hotel_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async getBottomBannerStatistics(hotel_id){
      try{
          const {data} = await $axios.get(`bottom-banner-statistic/${hotel_id}`);
          return data;
      }  catch (e){
          return e;
      }
    },
    async postBottomBannerStatistic(statistic){
        try{
            const {data} = await $axios.post(`add-bottom-banner-statistic`,statistic);
            return data;
        }catch (e){
            return e;
        }
    },
    addStatisticsShow(banner_id){
        try{
            $axios.get(`bottom-banner-event-show-click/${banner_id}`);
        }catch(e){
            return e;
        }
    },
    addStatisticsClickLink(banner_id){
        try{
            $axios.get(`bottom-banner-event-click-link/${banner_id}`);
        }catch(e){
            return e;
        }
    },
    addStatisticsClickVideoLink(banner_id){
        try{
            $axios.get(`bottom-banner-event-click-video-link/${banner_id}`);
        }catch(e){
            return e;
        }
    }
}
export default  BottomBannerService;