import Vue from 'vue'
import Vuex from 'vuex'
import landing from "./landing"
import loader from "@/store/loader";
import banners from "@/store/banners";
import bottomBanner from "@/store/bottomBanner";
import intro from "@/store/intro";
import products from "@/store/products";
import points from "@/store/points";
import events from "@/store/events"
import trails from "@/store/trails";
import radio from "@/store/radio";
import quests from "@/store/quests"
import HotelService from "@/services/hotel.service";
import realEstate from "@/store/realEstate";
import touristSpace from "@/store/touristSpace";
import segmentProducts from "@/store/segmentProducts";
import passcode from "@/store/passcode";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:"",
    hotel:null,
    version:null,
    error:null,
    back:false,
    langs:['en'],
    touristList:{
      group:null,
      index:null
    },
    segment_ids:[]
  },
  getters: {
  },
  mutations: {
    addLang(state,lang){
      state.langs.push(lang)
    },
    setContent(state,content){
      const candidate = state.hotel.menu.list.find(x => x.id === content.id);
      if(candidate){
        const candidateIndex = state.hotel.menu.list.indexOf(candidate);
        if(candidateIndex>=0){
          if(!state.hotel.menu.list[candidateIndex].content){
            state.hotel.menu.list.splice(candidateIndex,1,content);
          }else{
            const lang = Object.keys(content.content)[0];
            state.hotel.menu.list[candidateIndex].content[lang] = content.content[lang];
          }
        }
      }
    },
    setError(state,error){
      state.error = error;
    },
    setVersion(state,version){
      state.version = version.version;
    },
    setAdvancedHotel(state,advanced){
      if(state.hotel){
        state.hotel.advanced = advanced;
      }else{
        state.hotel = {
          advanced:advanced
        }
      }
    },
    setReviewsHotel(state,reviews){
      if(state.hotel){
        state.hotel.reviews = reviews;
      }else{
        state.hotel = {
          reviews:reviews
        }
      }
    },
    setBrandingHotel(state,branding){
      if(state.hotel){
        state.hotel.branding = branding;
      }else{
        state.hotel = {
          branding:branding
        }
      }
    },
    setMenuHotel(state,menu){
      if(state.hotel){
        state.hotel.menu = {
          list:menu
        }
      }else{
        state.hotel = {
          menu:{
            list:menu
          }
        }
      }
    },
    setOrderForm(state,form){
      if(state.hotel){
        state.hotel.order_form = form;
      }else{
        state.hotel = {
          order_form:form
        }
      }
    },
    setOrder(state,form){
      if(state.hotel){
        state.hotel.order = form;
      }else{
        state.hotel = {
          order:form
        }
      }
    },
    setMenuStylesHotel(state,styles){
      if(state.hotel){
        state.hotel.menu = {...state.hotel.menu,...styles}
      }else{
        state.hotel = {
          menu: {
            ...styles
          }
        }
      }
    },
    setSocialsHotel(state,socials){
      if(state.hotel){
        state.hotel.socials = socials
      }else{
        state.hotel = {
          socials:socials
        }
      }
    },
    setTariffHotel(state,tariff){
      if(state.hotel){
        state.hotel.tariff = tariff
      }else{
        state.hotel = {
          tariff:tariff
        }
      }
    },
  },
  actions: {
    async getContent({commit,state},payload){
      try{
        const data = await HotelService.getContent(payload);
        localStorage.setItem(`get-content/${payload.hotel_id}/${payload.page}/${payload.lang}?version=${window.version}`,JSON.stringify(data));
        commit('setContent',data);
      }catch (e){
        commit('setError',e);
      }
    }
  },
  modules: {
    landing:landing,
    loader:loader,
    banners:banners,
    bottomBanner:bottomBanner,
    intro:intro,
    products:products,
    points:points,
    events:events,
    trails:trails,
    radio:radio,
    quests:quests,
    realEstate:realEstate,
    touristSpace:touristSpace,
    segmentProducts:segmentProducts,
    passcode:passcode
  }
})
