import PasscodeService from "@/services/passcode.service";
export default {
  namespaced:true,
  state:{
    active:false,
    passcode:null
  },
  getters:{

  },
  actions:{
    async getPasscodeByHotel({commit},hotel_id){
      try{
        const data = await PasscodeService.getPasscodeByHotel(hotel_id);
        commit('setPasscode',data);
      }catch (e) {
        console.log(e);
      }
    },
    async postPasscode({commit},passcode) {
      try {
        const data = await PasscodeService.postPasscode(passcode);
        commit('setPasscode', data);
      } catch (e) {
        console.log(e);
      }
    },
    async putPasscode({commit},passcode){
      try{
        const data = await PasscodeService.putPasscode(passcode);
        commit('setPasscode',data);
      }catch (e) {
        console.log(e);
      }
    },
  },
  mutations:{
    setPasscode(state,passcode){
      state.passcode = passcode;
    },
    setActive(state,active){
      state.active = active;
    }
  }
}
