import $axios from "../plugins/axios";
import axios from 'axios'
const QuestsService = {
    async getQuestsList(hotel_id){
        try{
            const {data} = await $axios.get(`get-quests/${hotel_id}`);
            return data;
        }catch (e) {
            return e;
        }
    },
    async addSingleQuest(payload){
        try{
            const {data} = await $axios.post(`quest`,payload);
            return data;
        }catch(e){
            return e;
        }
    },
    async addGroupQuest(payload){
        try{
            const {data} = await $axios.post(`quest-group`,payload);
            return data;
        }catch(e){
            return e;
        }
    },
    async deleteQuestGroup(id){
        try {
            await $axios.delete(`quest-group/${id}`);
        }catch (e) {
            return e;
        }
    },
    async editQuestGroup(payload){
        try{
            const {data} = await $axios.put(`quest-group/${payload.id}`,payload);
            return data;
        }catch (e) {
            return e;

        }
    },
    async deleteQuestSingle(id){
        try{
            const {data} = await $axios.delete(`quest/${id}`);
            return data;
        }catch (e) {
            return e;
        }
    },
    async editQuestSingle(payload){
        try{
            const {data} = await $axios.put(`quest/${payload.id}`,payload);
            return data;
        }catch(e){
            return e;
        }
    },
    async getClientQuestsList(payload){
        try{
            const {data} = await axios(process.env.VUE_APP_API_URL+`mobi/get-my-quests-by-status`, {
                headers:{
                    'Authorization': 'Bearer ' + payload.token
                }
            });
            return data;
        }catch(e){
            return e;
        }
    },
    async getHotelQuestList(payload){
        try{
            const {data} = await axios(process.env.VUE_APP_API_URL+`mobi/get-quests-filtered/${payload.hotel_id}`, {
                headers:{
                    'Authorization': 'Bearer ' + payload.token
                }
            });
            return data
        }catch (e){
            return e;
        }
    },
    async addQuestGroupToUser(payload){
        try{
            const {data} = await axios(process.env.VUE_APP_API_URL+`mobi/get-quests-group/${payload.id}`, {
                headers:{
                    'Authorization': 'Bearer ' + payload.token
                }
            });
            return data;
        }catch (e){
            return e;
        }
    },
    async addQuestToUser(payload){
        try{
            const {data} = await axios(process.env.VUE_APP_API_URL+`mobi/get-quest/${payload.id}`, {
                headers:{
                    'Authorization': 'Bearer ' + payload.token
                }
            });
            return data;
        }catch(e){
            return e;
        }
    },
    async getQuestCategories(hotel_id) {
        try {
            const {data} = await $axios.get(`get-quest-categories/${hotel_id}`);
            return data;
        } catch (e) {
            return e;
        }
    },
    async getQuestCategoriesHotel(payload){
        try {
            const {data} = await axios(process.env.VUE_APP_API_URL+`mobi/get-all-quest-categories/${payload.id}`, {
                headers:{
                    'Authorization': 'Bearer ' + payload.token
                }
            });
            return data;
        } catch (e) {
            return e;
        }
    },
    async getMyQuestCategories(payload){
        try{
            const {data} = await axios(process.env.VUE_APP_API_URL+`mobi/get-quests-filtered/${payload.id}`, {
                headers:{
                    'Authorization': 'Bearer ' + payload.token
                }
            });
            return data;
        }catch(e){
            return e;
        }
    },
    async createQuestCategory(payload){
      try{
          const {data} = await $axios.post(`quest-category`,payload);
          return data;
      }  catch (e) {
          return e;
      }
    },
    async editQuestCategory(payload){
      try{
          const {data} = await $axios.put(`quest-category/${payload.id}`,payload);
          return data;
      }  catch (e) {
          return e;
      }
    },
    async deleteQuestCategory(payload){
        try{
            const {data} = await $axios.delete(`quest-category/${payload.hotel_id}/${payload.id}`)
            return data;
        }catch(e){
            return e;
        }
    },
    async getFinishPage(hotel_id){
        try{
            const {data} = await axios(process.env.VUE_APP_API_URL+`quest-finish/${hotel_id}`, {
              headers:{
                'Authorization': 'Bearer ' + payload.token
              }
            });
            return data
        }catch (e) {
            return e;
        }
    },
    async editFinishPage(payload){
        try{
            const {data} = await $axios.put(`quest-finish/${payload.id}`,payload);
            return data;
        }catch(e){
            return e;
        }
    },
    async startMyQuest(payload){
        try{
            const {data} = await axios(process.env.VUE_APP_API_URL+`mobi/start-my-quest/${payload.id}/${payload.hotel_id}`, {
                headers:{
                    'Authorization': 'Bearer ' + payload.token
                }
            });
            return data;
        }catch(e){
            return e;
        }
    },
    async getQuest(payload){
      try{
        const {data} = await axios.get(process.env.VUE_APP_API_URL + `mobi/quest?c=${payload.quest_id}`, {
          headers:{
            'Authorization': `Bearer ${payload.token}`
          }
        })
        return data;
      }catch (e) {
        return e;
      }
    },
  async endQuest(payload){
      try{
        const {data} = await axios.get(process.env.VUE_APP_API_URL+`mobi/end-quest?c=${payload.quest_id}`, {
          headers:{
            'Authorization': `Bearer ${payload.token}`
          }
        });
        return data;
      }catch(e){
        return e;
      }
  },
  async deleteSingleQuestFromProfile(payload){
      try{
        const {data} = await axios.delete(`${process.env.VUE_APP_API_URL}mobi/remove-quest-from-profile/${payload.id}`,{
          headers:{
            'Authorization': `Bearer ${payload.token}`
          }
        })
        return data;
      }catch (e) {
        return e;
      }
  },
  async deleteGroupQuestFromProfile(payload){
      try{
        const {data} = await axios.delete(`${process.env.VUE_APP_API_URL}mobi/remove-group-from-profile/${payload.id}`,{
          headers:{
            'Authorization': `Bearer ${payload.token}`
          }
        })
        return data;
      }catch (e) {
        return e;
      }
  },
  async startQuest(payload){
    try{
      const {data} = await  axios.get(`${process.env.VUE_APP_API_URL}mobi/start-quest/${payload.quest_id}/${payload.hotel_id}`,{
        headers:{
          'Authorization': `Bearer ${payload.token}`
        }
      })
      return data;
    }catch (e) {
      return e;
    }
  },
  async startGroup(payload){
      try{
        const {data} = await  axios.get(`${process.env.VUE_APP_API_URL}mobi/start-group/${payload.group_id}/${payload.hotel_id}`,{
          headers:{
            'Authorization': `Bearer ${payload.token}`
          }
        })
        return data;
      }catch (e) {
        return e;
      }
  }

};
export default QuestsService
