import axios from "axios";
import $axios from "@/plugins/axios"
const SegmentService = {
  async sendBookingData(payload){
    try{
        const {data} = await axios({
        url:process.env.VUE_APP_API_URL+`mobi/add-booking-data`,
          method:'post',
          data:payload,

          headers:{
            'Authorization': 'Bearer ' + payload.token
          }

      });
      return data;
    }catch (e) {
      return e
    }
  },
  async getSegmentModals(payload){
    try{
      const {data} = await axios({
        url:process.env.VUE_APP_API_URL+`mobi/get-segment-modal-banners/${payload.hotel_id}`,
        method:'post',
        data:{
          segment_ids:payload.segment_ids
        },
        headers:{
          'Authorization': 'Bearer ' + payload.token
        }
      });
      return data;
    }catch (e) {
      return e;
    }
  },
  async getSegmentIntro(payload){
    try{
    const {data} = await axios({
      url:process.env.VUE_APP_API_URL+`mobi/get-segment-intro-banners/${payload.hotel_id}`,
      method:'post',
      data:{
        segment_ids:payload.segment_ids
      },
      headers:{
        'Authorization': 'Bearer ' + payload.token
      }
    });
    return data;
  }catch (e) {
      return e;
    }
  },
  async getSegmentBottomBanner(payload){
    try{
    const {data} = await axios({
      url:process.env.VUE_APP_API_URL+`mobi/get-segment-bottom-banners/${payload.hotel_id}`,
      method:'post',
      data:{
        segment_ids:payload.segment_ids
      },
      headers:{
        'Authorization': 'Bearer ' + payload.token
      }
    });
    return data;
  }catch (e) {
    return e;
  }

  },
  async getSegmentProducts(payload){
    try{
      if(payload.segment_ids.length){
        const {data} = await axios({
          url:process.env.VUE_APP_API_URL+`mobi/get-segment-products/${payload.hotel_id}`,
          method:'post',
          data:{
            segment_ids:payload.segment_ids
          },
          headers:{
            'Authorization': 'Bearer ' + payload.token
          }
        });
        return data;
      }else{
        return [];
      }
    }catch (e) {
      return e;
    }
  },
  async postSegmentStatistics(payload){
    try{
      const {data} = await $axios.post(`segment-statistic`,payload);
      return data;
    }catch (e) {
      return e;
    }
  }
};
export default SegmentService
