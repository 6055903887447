import ApiService from "@/services/api.services.js"
import PointService from "@/services/point.service";
export default {
    namespaced: true,
    state: {
        mapDataList:null
    },
    mutations: {
        addPoint(state,point){
            const mapCandidate = state.mapDataList.find(x => x.id === point.map_id);
            if(mapCandidate){
                const mapGroupCandidate = mapCandidate.point_categories.find(x => x.id === point.point_category_id);
                if(mapGroupCandidate){
                    mapGroupCandidate.points.push(point);
                }
            }
        },
        setMapData(state,map){
            if(state.mapDataList){
                state.mapDataList.push(map[0]);
            }else{
                state.mapDataList = map;
            }
        },
        deletePoint(state,point){
            let mapCandidate = state.mapDataList.find(x => x.id === point.map_id);
            if(mapCandidate){
                let mapGroupCandidate = mapCandidate.point_categories.find(x => x.id === point.point_category_id);
                if(mapGroupCandidate){
                    let indexMap = state.mapDataList.indexOf(mapCandidate);
                    let indexCategory = mapCandidate.point_categories.indexOf(mapGroupCandidate);
                    state.mapDataList[indexMap].point_categories[indexCategory].points.splice(state.mapDataList[indexMap].point_categories[indexCategory].points.indexOf(point),1);
                }
            }
        },
        setNewCategory(state,category){
            category.points = [];
            let mapCandidate = state.mapDataList.find(x => x.id === category.map_id);
            if(mapCandidate){
                mapCandidate.point_categories.push(category);
            }
        },
        deleteCategory(state,category){
            let mapCandidate = state.mapDataList.find(x => x.id === category.map_id);
            if(mapCandidate){
                let mapGroupCandidate = mapCandidate.point_categories.find(x => x.id === category.id);
                if(mapGroupCandidate){
                    let indexMap = state.mapDataList.indexOf(mapCandidate);
                    let indexCategory = mapCandidate.point_categories.indexOf(mapGroupCandidate);
                    state.mapDataList[indexMap].point_categories.splice(indexCategory,1);
                }
            }
        },
        editPointCategory(state,pointcategory){
            const mapCandidate = state.mapDataList.find(x => x.id === pointcategory.map_id);
            if(mapCandidate){
                const mapCandidateIndex = state.mapDataList.indexOf(mapCandidate);
                if(mapCandidateIndex>=0){
                    const mapGroupCandidate = state.mapDataList[mapCandidateIndex].point_categories.find(x => x.id === pointcategory.id);
                    if(mapGroupCandidate){
                        const mapGroupCanididateIndex = state.mapDataList[mapCandidateIndex].point_categories.indexOf(mapGroupCandidate);
                        if(mapGroupCanididateIndex>=0){
                            state.mapDataList[mapCandidateIndex].point_categories.splice(mapGroupCanididateIndex,1,pointcategory);
                        }
                    }
                }
            }
        },
        editPoint(state,point){
            const mapCandidate = state.mapDataList.find(x => x.id === point.map_id);
            if(mapCandidate){
                const mapCandidateIndex = state.mapDataList.indexOf(mapCandidate);
                if(mapCandidateIndex>=0){
                    const mapGroupCandidate = state.mapDataList[mapCandidateIndex].point_categories.find(x => x.id === point.point_category_id);
                    if(mapGroupCandidate){
                        const mapGroupCanididateIndex = state.mapDataList[mapCandidateIndex].point_categories.indexOf(mapGroupCandidate);
                        if(mapGroupCanididateIndex>=0){
                        const pointCandidate = state.mapDataList[mapCandidateIndex].point_categories[mapGroupCanididateIndex].points.find(x => x.id === point.id);
                        if(pointCandidate){
                            const pointCandidateIndex = state.mapDataList[mapCandidateIndex].point_categories[mapGroupCanididateIndex].points.indexOf(pointCandidate);
                            if(pointCandidateIndex>=0){
                                state.mapDataList[mapCandidateIndex].point_categories[mapGroupCanididateIndex].points.splice(pointCandidateIndex,1,point);
                            }
                        }
                        }
                    }
                }
            }
        }
    },
    getters:{
        
    },
    actions: {
        async addPointCategory({commit},payload){
            try{
                const {data} = await ApiService.post(`/point-category`,payload);
                commit('setNewCategory',data);
            }catch(e){
                return e;
            }
        },
        async deletePointCategory({commit},payload){
            try{
                const {data} = await ApiService.delete(`/point-category/${payload.id}`)
                commit('deleteCategory',payload)
            }catch(e){
                return e;
            }
        },
        async savePoint({commit},payload){
            try{
                const {data} = await ApiService.put(`/point/${payload.id}`,payload);

            }catch(e){
                return e;
            }
        },
        async getMapData({commit},payload){
            try{
                const {data} = await ApiService.get(`/maps/${payload.hotel_id}/${payload.menu_id}`);
                commit('setMapData',data);
            }catch(e){
                return e;
            }
        },
        async updateMapData({commit},payload){
            try{
                await ApiService.put(`/map/${payload.id}`,payload)

            }  catch (e){
                return e;
            }
        },
        async addPoint({commit},payload){
            try {
                const {data} = await ApiService.post(`/point`,payload);
                commit('addPoint',data);
            } catch (e) {
                return e;
            }
        },
        async deletePoint({commit},point){
            try{
                await ApiService.delete(`/point/${point.id}`);
                commit('deletePoint',point);
            }catch(e){
                return e;
            }
        },
        async editPointCategory({commit},pointcategory){
            try{
                const data = await PointService.editPointCategory(pointcategory);
                commit('editPointCategory',data);
            }catch (e){
                return e;
            }
        },
        async editPoint({commit},point){
            try{
                const data = await PointService.editPoint(point);
                commit('editPoint',data);
            }catch (e){
                return e;
            }
        }
    },
}