export const defaultIcon = `${process.env.VUE_APP_STORAGE_URL}default-landing-icons/Icons_40-43.svg`;
import $axios from "@/plugins/axios"
export default{
    namespaced:true,
    state:{

    },
    getters:{

    },
    actions:{
        addStatistics({ commit }, data) {
            const payload = {
                hotel_id:data.hotel_id,
                page:data.page,
                link:data.link,
                type:data.type
            }
            return new Promise((resolve, reject) => {
                $axios
                    .post(`add-new-statistic`, payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        },
    },
    mutations:{

    }
}