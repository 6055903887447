import IntroServices from "@/services/intro.services";

export default {
    namespaced:true,
    state:{
        introList:[],
        introBanner:null,
        error:"",
        intro_upload_pro_top_img:false,
        intro_type_0_preview:false,
        intro_upload_pro_logo_img:false,
        saveIntro:[]
    },
    getters:{
    },
    actions:{
        async getIntroBanners({commit},hotel_id){
            try{
                const data = await IntroServices.getIntroBanners(hotel_id);
                commit('setIntroList',data);
            }catch(e){
               commit('setError',e);
            }
        },
        async getIntroBanner({commit},banner_id){
            try{
                const data = await IntroServices.getIntroBanner(banner_id);
                commit('setIntroBanner',data);
            }catch(e){
                commit('setError',e);
            }
        },
        async postIntroBanner({commit},banner){
            try{
                const data = await IntroServices.postIntroBanner(banner);
                commit('addBanner',data);
            }catch(e){
                commit('setError',e);
            }
        },
        async putIntroBanner({commit},banner){
            try{
                const data = await IntroServices.putIntroBanner(banner);
                commit('editBanner',data);
            }catch(e){
                commit('setError',e);
            }
        },
        async deleteIntroBanner({commit},banner_id){
            try{
                const data = await IntroServices.deleteIntroBanner(banner_id);
                commit('deleteBanner',data);
            }catch(e){
                commit('setError',e);
            }
        },
        postIntroBannerStatistic({commit},payload){
            try{
                IntroServices.postIntroBannerStatistic(payload);
            }catch(e){
                return e;
            }
        }
    },
    mutations:{
        setPreviewLogo(state,preview){
          state.intro_upload_pro_logo_img = preview
        },
        setPreviewTop(state,preview){
          state.intro_upload_pro_top_img= preview;
        },
        setPreviewImage(state,preview){
          state.intro_type_0_preview = preview;
        },
        setError(state,error){
            state.error = error.message;
        },
        setIntroList(state,list){
            state.introList = list;
        },
        setIntroBanner(state,banner){
            if(banner){
                if(state.introBanner){
                    const candidate = state.saveIntro.find(x => x.id === state.introBanner);
                    if(candidate){
                        const candidateIndex = state.saveIntro.indexOf(candidate);
                        if(candidateIndex>=0){
                            state.saveIntro.splice(candidateIndex,1,state.introBanner);
                        }
                    }else{
                        state.saveIntro.push(state.introBanner);
                    }
                }else{
                    state.saveIntro.push(banner);
                }
            }
            state.introBanner = banner;
        },
        addBanner(state,banner){
            state.introList.push(banner);
            state.introBanner = banner;
        },
        editBanner(state,banner){
            const candidate = state.introList.find(item => item.id === banner.id);
            if(candidate){
                const candidateIndex = state.introList.indexOf(candidate);
                if(candidateIndex>=0){
                    state.introList.splice(candidateIndex,1,banner);
                    state.introBanner = banner;
                }
            }
        },
        deleteBanner(state,banner){
            const candidate = state.introList.find(item => item.id === banner.id);
            if(candidate){
                const candidateIndex = state.introList.indexOf(candidate);
                if(candidateIndex>=0){
                    state.introList.splice(candidateIndex,1);
                    state.introBanner = null;
                }
            }
        }
    }
}