import $axios from "./plugins/axios";
import vuetify from "./plugins/vuetify";
import moment from "./plugins/moment"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import HotelLayout from "@/layouts/HotelLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import LandingLayout from "@/layouts/LandingLayout"
import * as VueGoogleMaps from "vue2-google-maps";
import VueOffline from "vue-offline";
import VueProgressBar from "vue-progressbar";
import VueNumericInput from "vue-numeric-input";
Vue.use(VueNumericInput);
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues
import wb from "./registerServiceWorker";

Vue.prototype.$workbox = wb;

Vue.component('GmapCluster', GmapCluster)

Vue.component("landing-layout",LandingLayout)
Vue.component("hotel-layout", HotelLayout);
Vue.component("empty-layout", EmptyLayout);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyABVj8O26ZfqreBo4ahH_ipCDWG8JUvSKo', //test
    // key: "AIzaSyCdUANK2bUuEAMYLWQjJeROckCuJYyI6u0", //original
    libraries: "places",
    v: "3.48.10",
  },
  autobindAllEvents: false,
  installComponents: true,
});
Vue.config.productionTip = true;

Vue.use(VueOffline);
const optionsProgress = {
  color: "#007db5",
  failedColor: "#874b4b",
  thickness: "2px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

Vue.use(VueProgressBar, optionsProgress);
new Vue({
  router,
  store,
  vuetify,
  $axios,
  moment,
  render: h => h(App)
}).$mount('#app');
