<template>
    <div v-if="renderComponent">
        <v-app class="app app_hotel" v-if="!block&&!passcode">
            <slot/>
        </v-app>
        <v-app v-if="block">
            <div style="height: 100vh;width:100vw;background:#fff;display: flex;justify-content: center;align-items: center;">
              <div style="display:flex;justify-content:center;align-items:center;flex-direction:column;">
                <div style="text-align:center;margin-bottom:15px;font-weight:bold;">Choose your hotel id</div>
                <v-text-field outlined :error="showError" :error-messages="errorMessage" dense type="number" v-model.number="hotel_value"></v-text-field>
                <v-btn outlined small color="primary" @click="openLink">Open</v-btn>
              </div>
            </div>
        </v-app>
        <v-app v-if="passcode" style="overflow:hidden;">
        <div style="height: 100vh;width:100vw;background:#fff;display: flex;justify-content: center;align-items: center;">
          <div style="min-width:360px;padding: 15px 40px;min-height: 570px;display: flex;flex-direction: column;align-items: center;justify-content: center" :style="`background:${passcodeBackground};`" v-if="passcode">
            <div style="margin-bottom: 15px;">
              <img :src="passcode.logo" alt="">
            </div>
            <div v-html="passcode.text[hotel?hotel.advanced.default_lang:'en']">
            </div>
            <div :class="{'shake' : animated}" style="display: flex;justify-content: space-evenly;align-items: center;width:60%;margin: 10px auto 25px auto;">
              <div style="height:10px;width:10px;border-radius: 50%;border:1px solid #000;" :style="`background:${+password.length-+item>=0?passcode.active_btn_color:passcode.btn_color};`" v-for="item in passcode.password.length" :key="item" ></div>
            </div>
            <div style="width:100%;margin-bottom:15px;display: flex;align-items: center;justify-content: space-evenly;">
              <div @click="addPassword(1)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">1</div>
              <div @click="addPassword(2)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">2</div>
              <div @click="addPassword(3)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">3</div>
            </div>
            <div style="width:100%;margin-bottom:15px;display: flex;align-items: center;justify-content: space-evenly;">
              <div @click="addPassword(4)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">4</div>
              <div @click="addPassword(5)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">5</div>
              <div @click="addPassword(6)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">6</div>
            </div>
            <div style="width:100%;margin-bottom:15px;display: flex;align-items: center;justify-content: space-evenly;">
              <div @click="addPassword(7)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">7</div>
              <div @click="addPassword(8)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">8</div>
              <div @click="addPassword(9)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">9</div>
            </div>
            <div style="position:relative;width:100%;margin-bottom:15px;display: flex;align-items: center;justify-content: space-evenly;">
              <div @click="addPassword(0)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">0</div>
              <div @click="clearPassword" style="cursor:pointer;position:absolute;right:35px;font-weight: bold;">Clear</div>
            </div>
          </div>
        </div>
      </v-app>
    </div>
</template>

<script>

import {mapState} from "vuex";
import axios from "@/plugins/axios"
import HotelService from "../services/hotel.service";
import titleMixin from "@/mixins/titleMixin";
import firebase from "firebase/app";
import "firebase/messaging";
import SegmentService from "../services/segment.service";
import PasscodeService from "../services/passcode.service";
export default {
  mixins:[titleMixin],
  title(){
    return ""
  },
    data: () => ({
      password:"",
      passcode:null,
      showError:false,
      errorMessage:"",
      renderComponent: true,
      block:false,
      hotel_value:"",
      animated:false,
      get requestPermission() {
        return (localStorage.getItem("notificationPref") === null)
      },
      set requestPermission(value) {
        localStorage.setItem("notificationPref", value)
      }
    }),
    props:['gotoHome'],
    async created() {
      this.passcode = await PasscodeService.getPasscodeByHotel(this.$route.params.id);
      if(!this.passcode){
        const response = await HotelService.checkBlockedUser(this.$route.params.id);
        if(response.data !== 'ok'){
          this.block = true;
        }
        if(this.$route.query){
          const data_json = {};
          for(let key in this.$route.query)
          {
            let value = this.changeValueType(this.$route.query[key]);
            if(key.includes('.')){
              const keys_arr = key.split('.');
              if(keys_arr[0]){
                data_json[keys_arr[0]] = {};
              }
              if(keys_arr[1]){
                data_json[keys_arr[0]][keys_arr[1]] = keys_arr.length===2?value:{};
              }
              if(keys_arr[2]){
                data_json[[keys_arr[0]][keys_arr[1]]][keys_arr[2]] = value
              }
            }
          }
          const payload = {
            hotel_id:+this.$route.params.id,
            data:data_json,
            token:localStorage.getItem(`token_${this.$route.params.id}`)
          };
          const data = await SegmentService.sendBookingData(payload);
          localStorage.setItem(`segment_info_${this.$route.params.id}`,JSON.stringify(data.segment_ids));
          this.$store.state.segment_ids = data.segment_ids;
          const payload_statistics = {
            hotel_id:+this.$route.params.id,
            segment_ids:data.segment_ids,
            platform:this.detectPlatform(navigator.userAgent),
            browser:this.detectBrowser(navigator.userAgent),
            browser_lang:this.detectLang(navigator.language),
            user_agent:navigator.userAgent
          };
          if(payload_statistics.segment_ids.length){
            await SegmentService.postSegmentStatistics(payload_statistics);
          }
        }
      }
    },
    async mounted() {
    if(!localStorage.getItem(`statistic_${this.$route.params.id}`)){
        await this.sendStatistics()
    }else{
      const time = +localStorage.getItem(`statistic_${this.$route.params.id}`);
      if(time <= +this.$moment().format('x')){
        await this.sendStatistics();
      }
    }
      if (
        localStorage.getItem(`token_${this.$route.params.id}`) &&
        !this.$store.state.hotel_user
      ) {
        const { data } = await HotelService.getUserInfo(
          localStorage.getItem(`token_${this.$route.params.id}`)
        );
        this.$store.state.hotel_user = data;
      }
      await this.enableNotifications();
      const favicon = document.querySelector("link[rel~='icon']");
    },
  computed: {
      ...mapState(['hotel']),
    passcodeBackground(){
      if(this.passcode.background){
        return `url(${this.passcode.background}) no-repeat top center / 100%`
      }else{
        return this.passcode.background_color;
      }
    }
    },
  methods:{
    clearPassword(){
      this.password = "";
    },
    async addPassword(number){
      this.password = this.password + `${number}`;
      if(this.password.length === this.passcode.password.length){
        if(this.password === this.passcode.password){
          this.passcode = null;
          const response = await HotelService.checkBlockedUser(this.$route.params.id);
          if(response.data !== 'ok'){
            this.block = true;
          }
          if(this.$route.query){
            const data_json = {};
            for(let key in this.$route.query)
            {
              let value = this.changeValueType(this.$route.query[key]);
              if(key.includes('.')){
                const keys_arr = key.split('.');
                if(keys_arr[0]){
                  data_json[keys_arr[0]] = {};
                }
                if(keys_arr[1]){
                  data_json[keys_arr[0]][keys_arr[1]] = keys_arr.length===2?value:{};
                }
                if(keys_arr[2]){
                  data_json[[keys_arr[0]][keys_arr[1]]][keys_arr[2]] = value
                }
              }
            }
            const payload = {
              hotel_id:+this.$route.params.id,
              data:data_json,
              token:localStorage.getItem(`token_${this.$route.params.id}`)
            };
            const data = await SegmentService.sendBookingData(payload);
            localStorage.setItem(`segment_info_${this.$route.params.id}`,JSON.stringify(data.segment_ids));
            this.$store.state.segment_ids = data.segment_ids;
            const payload_statistics = {
              hotel_id:+this.$route.params.id,
              segment_ids:data.segment_ids,
              platform:this.detectPlatform(navigator.userAgent),
              browser:this.detectBrowser(navigator.userAgent),
              browser_lang:this.detectLang(navigator.language),
              user_agent:navigator.userAgent
            };
            if(payload_statistics.segment_ids.length){
              await SegmentService.postSegmentStatistics(payload_statistics);
            }
          }
        }
        else{
          this.animated = true;
          this.password = "";
          setTimeout(() => {
            this.animated = false
          }, 1000)
        }
      }
    },
    changeValueType(val){
      if(val === 'true'){
        return true
      }
      if(isNaN(+val)){
        return val;
      }else{
        return +val;
      }
    },
    async enableNotifications() {
      if(!localStorage.getItem(`firebase_token_${this.$route.params.id}`)){
        if (!("Notification" in window)) {
          console.log("Notifications are not supported");
        } else if (Notification.permission === "granted") {
          this.initializeFirebase();
        } else if (Notification.permission !== "denied") {
          const permission = await Notification.requestPermission();
          if (permission === "granted") {
            this.initializeFirebase();
          }
        } else {
          console.log("No permission to send notification")
        }
        this.requestPermission = Notification.permission;
      }
    },
    registerToken(token) {
      axios.post(
        "/mobi/allow-notification",
        {
          device_token: token,
          hotel_id:this.$route.params.id,
          client_name:this.$store.state.hotel_user?`${this.$store.state.hotel_user.name} ${this.$store.state.hotel_user.last_name}`:null,
          client_email:this.$store.state.hotel_user?this.$store.state.hotel_user.email:null,
          lang:this.detectLang(navigator.language)
        },
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json"
          }
        }
      ).then(response => {
        console.log(response)
      });
    },
    initializeFirebase() {
      if (firebase.messaging.isSupported()) {
        let config = {
          apiKey: "AIzaSyAH04dtNwrT7vOfRPdQ9aoaEhzmaDtKJX0",
          authDomain: "guest-mobi.firebaseapp.com",
          projectId: "guest-mobi",
          storageBucket: "guest-mobi.appspot.com",
          messagingSenderId: "1013931251952",
          appId: "1:1013931251952:web:8ce17d5e27b850997b8a35",
          measurementId: "G-76432T12SE"
        };
        firebase.initializeApp(config);
        const messaging = firebase.messaging();
        messaging.getToken()
          .then((token) => {
            this.registerToken(token);
            localStorage.setItem(`firebase_token_${this.$route.params.id}`,token);
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
        messaging.onMessage( (payload) => {
          console.log(' Received message ', payload);
          window.navigator.serviceWorker.ready.then(function (registration) {
            payload.data.data = JSON.parse(JSON.stringify(payload.data));
            registration.showNotification(payload.data.title, payload.data);
          });
        });
      }
    },
    openLink(){
      this.errorMessage = "";
      this.error = false;
      if(!this.hotel_value){
        this.errorMessage = "Write your hotel id!";
        this.error = true;
        return
      }
      this.$router.push(`/m/${this.hotel_value}`);
      this.block = false;
      this.forceRerender();
    },
    detectBrowser(userAgent) {
      if((userAgent.indexOf("Opera") || userAgent.indexOf('OPR')) !== -1 ) {
        return 'Opera';
      } else if(userAgent.indexOf("Chrome") !== -1 ) {
        return 'Chrome';
      } else if(userAgent.indexOf("Safari") !== -1) {
        return 'Safari';
      } else if(userAgent.indexOf("Firefox") !== -1){
        return 'Firefox';
      } else if((userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) {
        return 'IE';
      } else {
        return 'Unknown';
      }
    },
    detectPlatform(userAgent) {
      if(userAgent.indexOf("iPhone") !== -1) {
        return 'iPhone';
      }else if(userAgent.indexOf("Android") !== -1) {
        return 'Android';
      } else if(userAgent.indexOf("Linux") !== -1) {
        return 'Linux';
      } else if(userAgent.indexOf("Mac") !== -1 ) {
        return 'Mac';
      } else if(userAgent.indexOf("Windows") !== -1) {
        return 'Windows';
      }else {
        return 'Unknown';
      }
    },
    detectLang(lang){
      const data = lang.split('-');
      if(data[1]){
        return `${data[0]}-${data[1].toUpperCase()}`
      }else{
        return `${data[0]}-${data[0].toUpperCase()}`;
      }
    },
    async sendStatistics() {
        localStorage.setItem(`statistic_${this.$route.params.id}`,this.$moment().add(1,'day').format('x'));
        const payload = {
          hotel_id:this.$route.params.id,
          platform:this.detectPlatform(navigator.userAgent),
          browser:this.detectBrowser(navigator.userAgent),
          browser_lang:this.detectLang(navigator.language),
          user_agent:navigator.userAgent
        }
        await HotelService.addUniqStatistic(payload);
      },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    }
  },
  watch: {
    '$route.params.page'() {
        this.forceRerender();
    }
  },

    beforeDestroy() {
  }
};
</script>

<style lang="scss">
  .shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

.swiper-pagination > .swiper-pagination-bullet{

  height:20px;
  width:20px;
}
.swiper-pagination > .swiper-pagination-bullet-active{
  background: #fff;

}
</style>
