import TouristSpaceService from "@/services/touristSpaceService";

export default {
  namespaced:true,
  state:{
    touristSpaces:[],
    touristSpace:null,
    error:false,
    error_message:"",
    availableDates:[],
    bookingLink:""
  },
  getters:{

  },
  actions:{
    async getFilteredSpaces({commit},payload){
      try{
        const data = await TouristSpaceService.getFilteredSpaces(payload);
        commit('setTouristSpaces',data);
      }catch (e) {
        commit('setError',e);
      }
    },
    async getAllSpaces({commit}){
      try{
        const data = await TouristSpaceService.getAllSpaces();
        commit('setTouristSpaces',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async getAllSpacesByCount({commit},count){
      try{
        const data = await TouristSpaceService.getCountSpaces(count);
        commit('setTouristSpaces',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async getSpace({commit},id){
      try{
        const data = await TouristSpaceService.getSpace(id);
        commit('setTouristSpace',data);
      }catch (e) {
        commit('setError',e);
      }
    },
    async getDatesByPeriod({commit},payload){
      try{
        const data = await TouristSpaceService.getDates(payload);
        commit('setDates',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async orderSpace({commit},payload){
      try{
        const data = await TouristSpaceService.orderSpace(payload);
        commit('setBookingLink',data);
      }catch (e) {
        commit('setError',e);
      }
    }
  },
  mutations:{
    setError(state,error){
      state.error_message = error.message;
      state.error = true;
    },
    setTouristSpaces(state,spaces){
      state.touristSpaces = spaces;
    },
    setTouristSpace(state,space){
      state.touristSpace = space;
    },
    setDates(state,dates){
      state.availableDates = dates;
    },
    setBookingLink(state,link){
      state.bookingLink = link;
    }
  }
}
