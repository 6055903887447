import $axios from "@/plugins/axios"

const EventService  = {
    async getEventCategories(hotel_id){
        try{
            const {data} = await $axios.get(`event-categories/${hotel_id}`);
            return data;
        }catch (e) {
            return e;
        }
    },
    async getEventCategory(category_id){
        try{
            const {data} = await $axios.get(`event-category/${category_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async addEventCategory(category){
        try{
            const {data} = await $axios.post(`event-category`,category);
            return data;
        }catch(e){
            return e;
        }
    },
    async editEventCategory(category){
        try{
            const {data} = await $axios.put(`event-categoryxhr.js?1a5c:220          GET https://api.guest.mobi/api/events/8699 401/${category.id}`,category);
            return data;
        }catch(e){
            return e;
        }
    },
    async deleteEventCategory(category_id){
        try{
            const {data} = await $axios.delete(`event-category/${category_id}`);
            return data;
        }catch (e) {
            return e;
        }
    },
  async getEventsForMainPage(payload){
      try{
        const {data} = await $axios.get(`event-filtered/${payload.hotel_id}?from=${payload.from}&to=${payload.to}`);
        return data;
      }catch (e) {
       return e;
      }
  },
  // async getEventById(id){
  //     try{
  //       const {data} = await $axios.get(`event/${id}`);
  //       return data;
  //     }catch (e) {
  //       return e;
  //     }
  // }
}
export default EventService;
