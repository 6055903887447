import SegmentService from "@/services/segment.service";

export default {
  namespaced:true,
  state:{
    segmentProductCategories:[],
  },
  getters:{

  },
  actions:{
    async getSegmentProductCategories({commit},payload){
      const data = await SegmentService.getSegmentProducts(payload);
      commit('setSegmentProductCategories',data);
    },
  },
  mutations:{
    setSegmentProductCategories(state,categories){
      state.segmentProductCategories = categories;
    },
  }
}
