<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
        <component :is="layout">
            <router-view/>
        </component>
      <InstallPWA v-if="prompt"/>
  </div>
</template>
<script>
import store from "@/store"
export default {
  components:{
    InstallPWA:() => import('@/components/helpers/InstallPWA.vue')
  },
  computed:{
    layout() {
      return this.$route.meta.layout || 'empty-layout'
    }
  },
  data(){
    return{
      prompt: false,
    }
  },
  created () {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.prompt = true;
      window.deferredPrompt = e;
    });
    window.addEventListener('appinstalled', (e) => {
      e.preventDefault();
      window.deferredPrompt = null;
      this.prompt = false;
    });
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      // console.log(from)
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish()
      // console.log(window.location.host);
    });

    this.$axios.interceptors.request.use(response => {
      this.$Progress.start()
      return response;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    this.$axios.interceptors.response.use(response => {
      this.$Progress.finish()
      return response;
    }, function (error) {

      if (error) {
        if (error.response) {
          if (error.response.status === 401) {
            store.dispatch('logout')
          }
        }
      }
      // Do something with request error
      return Promise.reject(error);
    });

  },
  methods:{
  },
  mounted () {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish()
  },
}
</script>
<style lang="scss">
@import "./assets/custom_fonts/Acrom/stylesheet.css";
@import "./assets/custom_fonts/Arial/stylesheet.css";
@import "./assets/custom_fonts/BebasNeue/stylesheet.css";
@import "./assets/custom_fonts/Circe/stylesheet.css";
@import "./assets/custom_fonts/DINPro/stylesheet.css";
@import "./assets/custom_fonts/Geometria/stylesheet.css";
@import "./assets/custom_fonts/GothamPro/stylesheet.css";
@import "./assets/custom_fonts/HelveticaNeueCyr/stylesheet.css";
@import "./assets/custom_fonts/MyriadPro/stylesheet.css";
@import "./assets/custom_fonts/ProximaNova/stylesheet.css";
@import "./assets/custom_fonts/SanFrancisco/stylesheet.css";
.slither-enter-active, .slither-leave-active {
    transition: transform 0.5s;
}

.slither-enter, .slither-leave-to {
    transform: translateX(100%);
}

.slither-enter-to, .slither-leave {
    transform: translateX(0);
}
.slither-reverse-enter-active, .slither-reverse-leave-active {
    transition: transform 0.5s;
}

.slither-reverse-enter, .slither-reverse-leave-to {
    transform: translateX(-100%);
}

.slither-reverse-enter-to, .slither-reverse-leave {
    transform: translateX(0);
}
</style>
