import $axios from "@/plugins/axios";
import moment from "moment";
const TouristSpaceService = {
  async getAllSpaces(){
    try{
      const {data} = await $axios.get(`https://tourist.hr/api/export/space/search`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getFilteredSpaces(payload){
    try{
      const {data} = await $axios.get(`https://tourist.hr/api/export/space?location_id=${payload.location_id}&start=${payload.start_date}&end=${payload.end_date}&date=${payload.start_date}+-+${payload.end_date}&${payload.persons}&price_range=${payload.price_start}%3B${payload.price_end}`)
    }catch (e) {
      return e;
    }
  },
  async getCountSpaces(payload){
    try{
      const {data} = await $axios.get(`https://tourist.hr/api/export/space/search?count=${payload}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getSpace(id){
    try{
      const {data} = await $axios.get(`https://tourist.hr/api/export/space?id=${id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getDates(payload){
    try{
      const weekArr = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      const {data} = await $axios.get(`https://tourist.hr/user/space/availability/loadDates?id=${payload.id}&start=${payload.start_date}&end=${payload.end_date}`);

      for(let i = 0;i<weekArr.indexOf(moment(payload.start_date).format('dddd'));i++){
        data.unshift('');
      }
      return data;
    }catch (e) {
      return e;
    }
  },
  async orderSpace(payload){
    try{
      const {data} = await $axios.post(`https://tourist.hr/booking/addToCart`,payload);
      return data;
    }catch (e) {
      return e;
    }
  }

};
export default TouristSpaceService
