import Vue from 'vue'
import VueRouter from 'vue-router'
import Error from "@/views/Error.vue";
Vue.use(VueRouter)

const defaultRoutes = [
  {
    path: '',
    name: 'main',
    component: () => import('@/views/Main.vue'),
    meta:{
      layout:"empty-layout"
    }
  },
  {
    path: "m/:id",
    name: "Hotel",
    component: () => import("@/views/Hotel/Hotel.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/events/:eventID",
    name: "EventDetail",
    component: () => import("@/views/Hotel/Pages/EventDetail"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/finish-quest",
    name: "MyQuestFinishPage",
    component: () => import("@/views/Hotel/Pages/MyQuestFinishPage.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/profile",
    name: "HotelProfile",
    component: () => import("@/views/Hotel/Pages/Profile.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/login",
    name: "HotelLogin",
    component: () => import("@/views/Hotel/Pages/Login.vue"),
  },
  {
    path: "m/:id/logout",
    name: "HotelLogout",
    component: () => import("@/views/Hotel/Pages/Logout.vue"),
  },
  {
    path: "m/:id/reviews",
    name: "Reviews",
    component: () => import("@/views/Hotel/Pages/Reviews.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/weather",
    name: "WeatherHotel",
    component: () => import("@/views/Hotel/Pages/Weather.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/favorite",
    name: "FavoriteHotel",
    component: () => import("@/views/Hotel/Pages/Favorite.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/develop",
    name: "DevelopHotel",
    component: () => import("@/views/Hotel/Pages/underConstructor.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/success",
    name: "FeedbackSuccessHotel",
    component: () => import("@/views/Hotel/Pages/FeedbackSuccess.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/review-success",
    name: "ReviewSuccessHotel",
    component: () => import("@/views/Hotel/Pages/Success.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/order-success",
    name: "OrderSuccessHotel",
    component: () => import("@/views/Hotel/Pages/OrderSuccess.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page",
    component: () => import("@/views/Hotel/Pages/MenuPage.vue"),
    name: "menuPage",
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/weather",
    component: () => import("@/views/Hotel/Pages/WeatherMenu.vue"),
    name: "WeatherMenuHotel",
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/no_authorizated/:page",
    name: "AuthHotel",
    component: () => import("@/views/Hotel/Pages/NoAuth.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/faq",
    name: "FaqHotel",
    component: () => import("@/views/Hotel/Pages/MenuPageFaq.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/contact",
    name: "ContactHotel",
    component: () => import("@/views/Hotel/Pages/MenuPageContact.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/events",
    name: "EventsHotel",
    component: () => import("@/views/Hotel/Pages/MenuPageEvents.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/events/:eventID",
    name: "EventDetailHotel",
    component: () => import("@/views/Hotel/Pages/MenuPageEventsDetails.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/gallery",
    name: "GalleryHotel",
    component: () => import("@/views/Hotel/Pages/MenuPageGallery.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/points",
    name: "PointsHotel",
    component: () => import("@/views/Hotel/Pages/MenuPagePoints.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/products/category",
    name: "menuPageProductCategory",
    component: () => import("@/views/Hotel/Pages/MenuPageProductCategory.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/products/category-test",
    name: "menuPageProductCategoryTest",
    component: () => import("@/views/Hotel/Pages/MenuPageProductCategoryTest.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/products/subcategory/:category",
    name: "menuPageProductSubcategory",
    component: () =>
        import("@/views/Hotel/Pages/MenuPageProductSubcategory.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/products/list/:category",
    name: "menuPageProductList",
    component: () => import("@/views/Hotel/Pages/MenuPageProductList.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/products/detail/:category/:product",
    name: "menuPageProductDetail",
    component: () => import("@/views/Hotel/Pages/MenuPageProductDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/dynamic",
    name: "DynamicHotel",
    component: () => import("@/views/Hotel/Pages/DynamicMenuPage.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/radio",
    name: "menuPageRadio",
    component: () => import("@/views/Hotel/Pages/MenuPageRadio.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/trail/list",
    name: "menuPageTrailList",
    component: () => import("@/views/Hotel/Pages/MenuPageTrailList.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/trail/list/:trailID",
    name: "menuPageTrailDetail",
    component: () => import("@/views/Hotel/Pages/MenuPageTrailDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/trail/list/:trailID/:pointID",
    name: "menuPageTrailPointDetail",
    component: () => import("@/views/Hotel/Pages/MenuPageTrailPointDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/dynamic/gallery",
    name: "menuPageGalleryDynamic",
    component: () => import("@/views/Hotel/Pages/MenuPageGalleryDynamic.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/dynamic/:groupIndex/:listingIndex",
    name: "DynamicListingHotel",
    component: () => import("@/views/Hotel/Pages/DynamicDetailListingPage.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/cart",
    name: "CartHotel",
    component: () => import("@/views/Hotel/Pages/Cart.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/quests",
    name: "QuestsHotelCategories",
    component: () => import("@/views/Hotel/Pages/QuestCategory.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/quests/category/:categoryID/single/:questID",
    name: "QuestsSingleDetail",
    component: () => import("@/views/Hotel/Pages/QuestDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/quests/category/:categoryID/group/:groupID",
    name: "QuestGroupDetail",
    component: () => import("@/views/Hotel/Pages/QuestGroupDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/my-quests/category/:categoryID/single/:questID",
    name: "myQuestsSingleDetail",
    component: () => import("@/views/Hotel/Pages/MyQuestDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/my-quests/category/:categoryID/group/:groupID",
    name: "myQuestGroupDetail",
    component: () => import("@/views/Hotel/Pages/MyQuestGroupDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/my-quests",
    name: "myQuestsHotelCategoriesList",
    component: () => import("@/views/Hotel/Pages/MyQuestCategoryList.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/quests/category/:categoryID",
    name: "QuestsHotelCategoriesList",
    component: () => import("@/views/Hotel/Pages/QuestCategoryList.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page",
    name: "SubmenuHotel",
    component: () => import("@/views/Hotel/Pages/SubMenuPage.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/listing/:page/:menuIndex",
    name: "SubmenuListingHotel",
    component: () => import("@/views/Hotel/Pages/SubMenuListingPage.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuID/faq",
    name: "SubmenuFaqHotel",
    component: () => import("@/views/Hotel/Pages/SubMenuPageFaq.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuID/contact",
    name: "SubmenuContactHotel",
    component: () => import("@/views/Hotel/Pages/SubMenuPageContact.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuID/weather",
    name: "SubmenuWeatherHotel",
    component: () => import("@/views/Hotel/Pages/SubMenuPageWeather.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuID/events",
    name: "SubmenuEventsHotel",
    component: () => import("@/views/Hotel/Pages/SubMenuPageEvents.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/gallery",
    name: "SubMenuPageGallery",
    component: () => import("@/views/Hotel/Pages/SubMenuPageGallery.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/points",
    name: "SubMenuPagePoints",
    component: () => import("@/views/Hotel/Pages/SubMenuPagePoints.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/products/category",
    name: "SubMenuPageProductCategory",
    component: () => import("@/views/Hotel/Pages/SubMenuPageProductCategory.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/products/subcategory/:category",
    name: "SubMenuPageProductSubcategory",
    component: () =>
        import("@/views/Hotel/Pages/SubMenuPageProductSubcategory.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/products/list/:category",
    name: "SubMenuPageProductList",
    component: () => import("@/views/Hotel/Pages/SubMenuPageProductList.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/products/detail/:category/:product",
    name: "SubMenuPageProductDetail",
    component: () => import("@/views/Hotel/Pages/SubMenuPageProductDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/dynamic",
    name: "SubMenuPageDynamic",
    component: () => import("@/views/Hotel/Pages/SubMenuPageDynamic.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/trail/list",
    name: "SubMenuTrailList",
    component: () => import("@/views/Hotel/Pages/SubMenuTrailList.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/trail/list/:trailID",
    name: "SubMenuTrailDetail",
    component: () => import("@/views/Hotel/Pages/SubMenuTrailDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/trail/list/:trailID/:pointID",
    name: "SubMenuTrailPointDetail",
    component: () => import("@/views/Hotel/Pages/SubMenuTrailPointDetail.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/dynamic/gallery",
    name: "SubMenuPageDynamicGallery",
    component: () => import("@/views/Hotel/Pages/SubMenuPageDynamicGallery.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/:page/:menuIndex/dynamic/:groupIndex/:listingIndex",
    name: "SubMenuPageDynamicDetailListing",
    component: () => import("@/views/Hotel/Pages/SubMenuPageDynamicDetailListing.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/submenu/listing/:page/:menuIndex/:listingGroup/:listingIndex",
    name: "SubmenuListingDetailHotel",
    component: () => import("@/views/Hotel/Pages/SubMenuFullListingPage.vue"),
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path: "m/:id/menu/:page/:listingGroup/:listing",
    component: () => import("@/views/Hotel/Pages/Listing.vue"),
    name: "DetailListing",
    meta: {
      layout: "hotel-layout",
    },
  },
  {
    path:`m/:id/menu/:page/tplaces`,
    name: "TouristList",
    component:() => import("@/views/Hotel/Pages/TouristListingList.vue"),
    meta:{
      layout: "hotel-layout"
    }
  },
  {
    path:`m/:id/menu/:page/tplaces/:group/:listing`,
    name: "TouristDetail",
    component:() => import("@/views/Hotel/Pages/TouristListingDetail.vue"),
    meta:{
      layout: "hotel-layout"
    }
  },
  {
    path:`m/:id/menu/:page/tproperty`,
    name:"TouristSpaces",
    component:() => import("@/views/Hotel/Pages/TouristSpaceList")
  },
  {
    path:`m/:id/menu/:page/tproperty/:group/:listing`,
    name: "TouristSpaceDetail",
    component:() => import("@/views/Hotel/Pages/TouristSpaceDetail.vue"),
    meta:{
      layout: "hotel-layout"
    }
  },
  {
    path:`m/:id/menu/:page/estates`,
    name:"RealEstates",
    component:() => import("@/views/Hotel/Pages/RealEstateList")
  },
  {
    path:`m/:id/menu/:page/estates/:group/:listing`,
    name: "RealEstateDetail",
    component:() => import("@/views/Hotel/Pages/RealEstateDetail.vue"),
    meta:{
      layout: "hotel-layout"
    }
  },
  {
    path:`m/:id/products/:categoryID/:productID`,
    name: "SegmentProductDetail",
    component:() => import("@/components/Constructor/Marketing/SegmentProductDetail.vue"),
    meta:{
      layout: "hotel-layout"
    }
  },
  {
    path: ":driver/callback",
    name: "callback",
    component: () => import("@/views/Hotel/Pages/AuthDriverProvider.vue")
  },
  // {
  //   path:"m/:id/test",
  //   name:"test",
  //   component:() => import("@/views/Hotel/Pages/RealEstatePage")
  // },
]

const routes = [
  {
    path: "/:lang([r|h][u|r])?",
    component: {
      render: (h) => h("router-view"),
    },
    children: defaultRoutes,
  },
  {
    path: "*",
    name: "error",
    component: Error,
    meta: {
      layout: "empty-layout",
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const methods = ["push","replace"];
methods.forEach(method => {
  const originalMethod = router[method];
  router[method] = function m(location) {
    return originalMethod.call(this, location).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        // capture exception
        console.log(error);
      }
    })
  };
});

router.beforeEach((to, from, next) => {
  let checkID = to.path.split('/');
  if(+checkID[2] === 8430){
    window.location.href = `https://welcomepage.eu/m/8636`;
  }
  next();
});

export default router
