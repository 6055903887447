import $axios from "../plugins/axios";

const ModalBannersService = {
    async getBanners(hotel_id){
        try{
            const {data} = await $axios.get(`banner-models/${hotel_id}`);
            return data;
        }   catch (e){
            return e;
        }
    },
    async getOneBanner(banner_id){
        try{
            const {data} = await $axios.get(`banner-model/${banner_id}`);
            return data;
        }catch (e){
            return e;
        }
    },
    async createBanner(payload){
        try{
            const {data} =  await $axios.post(`banner-model`,payload);
            return data;
        }catch (e){
            return e;
        }
    },
    async editBanner(payload){
        try{
            const {data} = await $axios.put(`banner-model/${payload.id}`,payload);
            return data;
        }catch (e){
            return e;
        }
    },
    async deleteBanner(banner_id){
        try{
            const {data} = await $axios.delete(`banner-model/${banner_id}`);
            return data;
        }catch (e){
            return e;
        }
    },
    async postShowBannerStatistic(banner_id){
        try{
            const {data} = await $axios.get(`event-show-click/${banner_id}`)
        }catch(e){
            return e;
        }
    },
    addStatisticsShow(banner_id){
        try{
            $axios.get(`event-show-click/${banner_id}`)
        }catch(e){
            return e;
        }
    },
    addStatisticsClickLink(banner_id){
        try{
            $axios.get(`event-click-link/${banner_id}`);
        }catch(e){
            return e;
        }
    },
    addStatisticsClickVideoLink(banner_id) {
        try{
            $axios.get(`event-click-video-link/${banner_id}`);
        }catch(e){
            return e;
        }

    }
}
export default ModalBannersService;