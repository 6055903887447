import { Workbox } from "workbox-window";
let wb;

if ("serviceWorker" in navigator) {
  wb = new Workbox(`/service-worker.js`);
  let CACHE_NAME = `${window.location.href}`;
  wb.addEventListener('fetch', function(event) {
    event.respondWith(
      caches.open(CACHE_NAME).then(function(cache) {
        return cache.match(event.request).then(function (response) {
          return response || fetch(event.request).then(function(response) {
            cache.put(event.request, response.clone());
            return response;
          })
        })
      })
    );
  });
  wb.register();
} else {
  wb = null;
}

export default wb;
